
export default {
  name: "IndexPage",
  transition: "default",
  async asyncData({ $axios, params, error }) {
    try {
      const qs = require("qs");
      const query = qs.stringify(
        {
          // publicationState: "preview",
          filters: {
            slug: {
              $eq: "home",
            },
          },
        },
        {
          encodeValuesOnly: true,
        }
      );

      // console.log("HOME QUERY: " + query);
      var data = await $axios.$get(
        `${process.env.api_url}/api/pages?${query}&populate=deep&locale=all`
      );
      const breakBlockData = await $axios.$get(
        `${process.env.api_url}/api/boxes-break-blocks?populate=deep&locale=all`
      );
      // console.log("HOME PARAMS: ", params);
      // console.log("HOME DATA: ", data);

      if (!data.data.length) {
        data = false;
      }
      
      return {
        data: {
          data: data.data[0],
          breakBlockData:
            breakBlockData.data.length && breakBlockData.data[0].attributes,
        },
      };
    } catch (err) {
      error({
        statusCode: 404,
        message: "Page not found",
      });
    }
  },
  data() {
    return {
      cookies: this.$cookies,
      availableLang: {
        de: "Deutsch",
        en: "English",
      },
    };
  },
};
